import BaseFormatter from './base'
import { has, hasFields } from '../../helpers'

/**
 * Format report response data for ODF file details page
 */
export default class OpenDocumentDetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */
  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const result: { [key: string]: any } = {}

    const resource = this.getResource(data, 'file')
    if (!resource) return

    const fileType = (!data?.file?.type || data.file.type === 'other') ?
      this.getFileType(resource) : data.file.type
    if (fileType !== 'open-document') {
      return
    }

    const odData = resource.extendedData
    this.copyGrouped(odData, result)

    if (has(resource, 'metaData')) {
      result.meta = resource.metaData
    }

    if (hasFields(result)) {
      report.open_document_details = result
      report.open_document_details.overview = { ...report.details.overview, ...report.open_document_details.overview }
      delete report.details
    }

  }

  /**
   * Just copy data that is pregrouped to result report
   *
   * @param data
   * @param result
   */
  copyGrouped(data: { [key: string]: any }, result: { [key: string]: any }): void {
    const names: { [key: string]: any } = {
      'basicProcedures': 'basicProcedures'
    }

    Object.keys(names).forEach(name => {
      if (has(data, name)) {
        const useName = names[name]
        result[useName] = data[name]
      }
    })
  }
}

import { ScanReport } from '../types';

import DetailsFormatter from './formatters/details'
import UrlDetailsFormatter from './formatters/url-details'
import OverviewFormatter from './formatters/overview'
import YaraFormatter from './formatters/yara'
import ProgressFormatter from './formatters/progress'
import ExtractedStringsFormatter from './formatters/strings'
import IOCFormatter from './formatters/ioc'
import EmulationDataFormatter from './formatters/emulation-data'
import OsintFormatter from './formatters/osint'
import DisassemblyFormatter from './formatters/disassambly'
import GeolocationFormatter from './formatters/geolocation'
import MergeIOCGeolocationFormatter from './formatters/merge-ioc-geolocation'
import VerdictFormatter from './formatters/verdict'
import VisualizationFormatter from './formatters/visualization'
import PreviewFormatter from './formatters/preview'
import ExtractedFilesFormatter from './formatters/extracted-files'
import WhoisFormatter from './formatters/whois'
import MergeIOCOsintVerdictFormatter from './formatters/merge-ioc-osint-verdicts'
import { UpdateEmulationFormatter } from './formatters/update-emulation'
import { ApplicationConfiguration } from '../../shared/types';
import BaseFormatter from './formatters/base';
import IOCFilterFormatter from './formatters/ioc-filter';

/**
 * Format response from server, containing report data.
 * Data is simplified and grouped in a way, that is better to use
 *   for displaying to user.
 *
 * This is just a wrapper around handlers for each report subpage.
 * Each of them formats it's own portion of data.
 */
export class ReportFormatter {
  constructor(config: ApplicationConfiguration, exporting: boolean) {
    BaseFormatter.supportedTypes = config.supportedTypes
    BaseFormatter.fileCategories = config.fileCategories
    ExtractedStringsFormatter.exporting = exporting
    ExtractedStringsFormatter.exportLimit = config.reportFormat.stringsLimit
    ExtractedStringsFormatter.exportStringsMode = config.reportFormat.stringsMode
  }

  formatters = [
    new DetailsFormatter(),
    new UrlDetailsFormatter(),
    new OverviewFormatter(),
    new YaraFormatter(),
    new ProgressFormatter(),
    new ExtractedStringsFormatter(),
    new EmulationDataFormatter(),
    new DisassemblyFormatter(),
    new VerdictFormatter(),
    new VisualizationFormatter(),
    new PreviewFormatter(),
    new ExtractedFilesFormatter(),
    new WhoisFormatter(),

    new OsintFormatter(),
    new IOCFormatter(),
    new GeolocationFormatter(),
    new MergeIOCGeolocationFormatter(), // Should be called after IOCFormatter and GeolocationFormatter
    new MergeIOCOsintVerdictFormatter(), // Should be called after IOCFormatter and OsintFormatter
    new IOCFilterFormatter(), // Should be called after all IOC related formatters
    new UpdateEmulationFormatter()
  ];

  /**
   * Format report data
   *
   * @param {object} data
   */
  format(data: { [key: string]: any }): ScanReport {
    // Debug field
    if (data) {
      delete data.filter_errors
    }

    const reportsIds = !data || !data?.reports ? [] : Object.keys(data.reports);
    if (!reportsIds.length) {
      return data as ScanReport;
    }

    // All general data, not related to specific reports,
    // should not be formatted. Use it as is.
    const { reports, ...result } = data;
    result.reports = {};

    // Format each report
    reportsIds.forEach(reportId => {
      const reportData = reports[reportId];
      const report = {};

      reportData.id = reportId
      this.formatters.forEach(formatter => {
        formatter.format(reportData, report);
      })

      result.reports[reportId] = report;
    })

    const typedResult: ScanReport = result as ScanReport;

    return typedResult;
  }
}

import { inc } from 'app/modules/shared/helpers'
import { ReportFilterSection, ReportFilterSectionType, ReportFilterStat, ReportPageMultiFileFilterSettings } from '../../components/filter/types'
import { isStringInteresting } from '../../features/extracted-strings/helpers'
import { ExtractedStringsFilterStat, ReportExportStringsMode } from '../../features/extracted-strings/types'
import { AllExtractedStrings, AllFilesTypes, ExtractedString, ExtractedStringsGroup, mainFileKey } from '../../types'
import BaseFormatter from './base'
import { formatEnumItem } from '../../helpers'

/**
 * Format data needed for Extracted Strings report subpage.
 *
 * Performs grouping of strings, so that:
 * - result report has `strings` item
 * - that item has the following items:
 *   - `main` for main report file, containing all it's strings in the form given in report
 *   - `extractedFiles`, containing hash -> strings map for all extracted files for given main report file
 *   - `downloadedFiles`, containing hash -> strings map for all downloaded files for given main report file
 */
export default class ExtractedStringsFormatter extends BaseFormatter {
  static exporting: boolean = false
  static exportLimit: number
  static exportStringsMode: ReportExportStringsMode
  count: number = 0

  /**
   * Format original report data
   * Do not perform fields move in formatter, because there can be lots of strings
   * and that additional loop in formatter could hit the performance
   * @param data
   * @param report
   */
  format(data: {[key: string]: any}, report: {[key: string]: any}): void {
    this.count = 0
    let result: AllExtractedStrings = {}
    const fileResource = this.getResource(data, 'file')
    const filter: ReportPageMultiFileFilterSettings = {files: {}}

    if (fileResource) {
      if (fileResource.strings?.length) {
        const [strings, sections, stat] = this.sanitizeStrings(fileResource.strings)

        result[mainFileKey] = strings
        filter.files[mainFileKey] = {sections, stat}
      }
      if (typeof fileResource.extractedFiles !== 'undefined') {
        this.formatFilesStrings(fileResource.extractedFiles, result, 'extractedFiles', filter)
      }
    }
    const downloadResource = this.getResource(data, 'file-download')
    if (downloadResource?.fileDownloadResults) {
      this.formatFilesStrings(downloadResource.fileDownloadResults, result, 'downloadedFiles', filter)
    }

    result = this.applyComplexExportOptions(result)

    if (Object.keys(result).length) {
      report.strings = {
        strings: result,
        filter
      }
    }
  }

  /**
   * Format strings for extracted and downloaded files
   *
   * @param data
   * @param result
   * @param resultField
   */
  formatFilesStrings(
    data: {[key: string]: any},
    result: AllExtractedStrings,
    resultField: string,
    filter: ReportPageMultiFileFilterSettings
  ): void {
    const filesResult: {[hash: string]: any[]} = {}

    data.forEach((file: any) => {
      const hash = file.digests['SHA-256']

      if (file.strings?.length) {
        const [strings, sections, stat] = this.sanitizeStrings(file.strings)

        filesResult[hash] = strings
        filter.files[hash] = {sections: sections, stat}
      }
    })

    const fileHashes = Object.keys(filesResult)
    if (fileHashes.length) {
      const key = resultField as AllFilesTypes
      result[key] = filesResult
    }
  }

  sanitizeStrings(strings: ExtractedStringsGroup[]): [ExtractedString[], ReportFilterSection[], ReportFilterStat] {
    const filterSections = this.getFilterSectionsTmpl()
    const filterStat = this.getFilterStatTmpl()
    const sectionsCache: {[key: string]: boolean} = {}
    let result: ExtractedString[] = []

    for (const str of strings) {
      str.references.forEach((ref: ExtractedString) => {
        ref.str = ref.str.trim()
        if (!ref.str.length) {
          return
        }

        if (ref.metaData) {
          if (ref.type !== 'ASCII' && ref.type) {
            ref.metaData = { encoding: ref.type, ...ref.metaData }
          }

          delete ref.type
          delete ref.metaData.offset
          delete ref.metaData.emulationEventIDs
        }

        this.gatherFilterSections(ref, filterSections, sectionsCache)
        this.gatherFilterStat(ref, filterStat)
        result.push(ref)
      })
    }

    result = this.applySimpleExportOptions(result)

    return [result, Object.values(filterSections), filterStat]
  }

  // Limit amount of strings depending on export options.
  applySimpleExportOptions(items: ExtractedString[]) {
    if (!ExtractedStringsFormatter.exporting) {
      return items
    }

    let result: ExtractedString[] = items
    const mode = ExtractedStringsFormatter.exportStringsMode
    const limit = ExtractedStringsFormatter.exportLimit
    const keepCount = limit > this.count ? limit - this.count : 0 // check just in case, shouldn't happen

    // Respect limit on strings, no filtering
    if (mode === ReportExportStringsMode.All) {
      result = items.slice(0, keepCount)
    }

    // Only return interesting strings, respecting limit
    else if (mode === ReportExportStringsMode.Interesting) {
      const interesting: ExtractedString[] = items.filter(item => isStringInteresting(item))
      result = interesting.slice(0, keepCount)
    }

    this.count += result.length
    return result
  }

  // Keep interesting strings with respect to limit, and if limit is not reached - non-interesting till the limit
  applyComplexExportOptions(grouped: AllExtractedStrings) {
    const skip = (
      !ExtractedStringsFormatter.exporting ||
      ExtractedStringsFormatter.exportStringsMode !== ReportExportStringsMode.PreferInteresting
    )
    if (skip) {
      return grouped
    }

    let count = 0
    const result: AllExtractedStrings = {}
    const limit = ExtractedStringsFormatter.exportLimit

    // Go through strings, grouped by files, and apply filtering and limit conditions
    function filterStrings(condition: (item: ExtractedString) => boolean) {
      if (typeof grouped[mainFileKey] !== 'undefined') {
        const keepItems = filterFileStrings(condition, grouped[mainFileKey])
        if (keepItems.length) {
          if (typeof result[mainFileKey] === 'undefined') {
            result[mainFileKey] = []
          }
          result[mainFileKey] = [...result[mainFileKey], ...keepItems]
        }
      }

      for (let key of [AllFilesTypes.ExtractedFiles, AllFilesTypes.DownloadedFiles]) {
        const byFile = grouped[key] || {}
        for (let hash in byFile) {
          const keepItems = filterFileStrings(condition, byFile[hash])
          if (keepItems.length) {
            if (typeof result[key] === 'undefined') {
              result[key] = {}
            }
            if (typeof result[key][hash] === 'undefined') {
              result[key][hash] = []
            }
            result[key][hash] = [...result[key][hash], ...keepItems]
          }
        }
      }
    }

    // Apply filter and limit to strings of a single file
    function filterFileStrings(condition: (item: ExtractedString) => boolean, fileItems: ExtractedString[]) {
      const items: ExtractedString[] = fileItems.filter(condition)
      const keepCount = limit - count
      const keepItems = items.slice(0, keepCount)
      count += keepItems.length

      return keepItems
    }

    filterStrings((item: ExtractedString) => isStringInteresting(item))
    filterStrings((item: ExtractedString) => !isStringInteresting(item))

    return result
  }

  gatherFilterSections(
    item: ExtractedString,
    sections: {[sectionName: string]: ReportFilterSection},
    sectionsCache: {[key: string]: boolean}
  ) {
    if (isStringInteresting(item) && typeof sectionsCache.interesting === 'undefined') {
      sections.type.items.push({value: 'interesting'})
      sectionsCache.interesting = true
    }
    if (item.metaData?.apiref && typeof sectionsCache.apiRef === 'undefined') {
      sections.type.items.push({value: 'apiRef', name: 'api-reference'})
      sectionsCache.apiRef = true
    }
    if (item.metaData?.triggeredConsumerIDs && typeof sectionsCache.signal === 'undefined') {
      sections.type.items.push({value: 'signal', name: 'triggered-signal'})
      sectionsCache.signal = true
    }
    if (item.metaData?.encoding === 'UTF8' && typeof sectionsCache.utf8 === 'undefined') {
      sections.type.items.push({value: 'utf8'})
      sectionsCache.utf8 = true
    }
    if (item.origin && typeof sectionsCache[item.origin] === 'undefined') {
      sections.origin.items.push({value: item.origin, name: formatEnumItem(item.origin)})
      sectionsCache[item.origin] = true
    }
  }

  gatherFilterStat(item: ExtractedString, stat: ExtractedStringsFilterStat) {
    const statTypes = stat.types

    isStringInteresting(item) && (statTypes.type.interesting = inc(statTypes.type.interesting))
    item.metaData?.apiref && (statTypes.type.apiRef = inc(statTypes.type.apiRef))
    item.metaData?.triggeredConsumerIDs && (statTypes.type.signal = inc(statTypes.type.signal))
    item.metaData?.encoding === 'UTF8' && (statTypes.type.utf8 = inc(statTypes.type.utf8))
    statTypes.origin[item.origin] = inc(statTypes.origin[item.origin])

    stat.total = inc(stat.total)
  }

  getFilterSectionsTmpl() {
    const sections: {[sectionName: string]: ReportFilterSection} = {
      search: {type: ReportFilterSectionType.Search, name: 'search', items: [], placeholder: 'search-string'},
      type: {type: ReportFilterSectionType.Flags, name: 'type', items: []},
      origin: {type: ReportFilterSectionType.Flags, name: 'origin', items: []},
    }

    return sections
  }

  getFilterStatTmpl() {
    const stat: ExtractedStringsFilterStat = {
      types: {type: {}, origin: {}}, total: 0
    }

    return stat
  }
}

import BaseFormatter from './base'
import DefaultFormatter from './default'
import PEDetailsFormatter from './pe-details'
import MsOfficeDetailsFormatter from './ms-office-details'
import EmlDetailsFormatter from './eml-details'
import MboxDetailsFormatter from './mbox-details'
import PdfDetailsFormatter from './pdf-details'
import LnkDetailsFormatter from './lnk-details'
import ElfDetailsFormatter from './elf-details'
import ApkDetailsFormatter from './apk-details'
import UnknownFileTypeDetailsFormatter from './unknown-file-type-details'
import { AllExtractedFilesTypes } from '../../types'
import MsiDetailsFormatter from './msi-details'
import OpenDocumentDetailsFormatter from './open-document-details'

/**
 * Format data needed for Disassembly Sections report subpage.
 */
export default class DetailsFormatter extends BaseFormatter {
  /**
   * Format original report data
   *
   * @param data
   * @param report
   */

  formatters = [
    new DefaultFormatter(),
    new MsiDetailsFormatter(),
    new PEDetailsFormatter(),
    new MsOfficeDetailsFormatter(),
    new EmlDetailsFormatter(),
    new MboxDetailsFormatter(),
    new PdfDetailsFormatter(),
    new LnkDetailsFormatter(),
    new ElfDetailsFormatter(),
    new ApkDetailsFormatter(),
    new OpenDocumentDetailsFormatter(),
    new UnknownFileTypeDetailsFormatter()
  ];

  format(data: { [key: string]: any }, report: { [key: string]: any }): void {
    this.formatters.forEach(formatter => {
      formatter.format(data, report);
    })

    report['derived_details'] = {}
    this.formatDerivedFiles(data, report['derived_details'])
    if (!Object.keys(report['derived_details'])) {
      delete report['derived_details']
    }
  }

  formatDerivedFiles(data: { [key: string]: any }, report: { [key: string]: any }): void {
    const allExtracted: any[] = this.getExtractedFiles(data)
    const extracted = allExtracted ? allExtracted.filter(file => file.origin?.type !== 'INPUT_CONTEXT') : undefined
    const context = allExtracted ? allExtracted.filter(file => file.origin?.type === 'INPUT_CONTEXT') : undefined

    let result: { [key in AllExtractedFilesTypes]?: any } = {}
    extracted && extracted.forEach((file: any) => this.formatDerivedFile(file, result))
    if (Object.keys(result).length) {
      report[AllExtractedFilesTypes.ExtractedFiles] = result
    }

    result = {}
    context && context.forEach((file: any) => this.formatDerivedFile(file, result))
    if (Object.keys(result).length) {
      report[AllExtractedFilesTypes.ContextFiles] = result
    }

    result = {}
    const downloaded = this.getDownloadedFiles(data)
    downloaded && downloaded.forEach((file: any) => this.formatDerivedFile(file, result))
    if (Object.keys(result).length) {
      report[AllExtractedFilesTypes.DownloadedFiles] = result
    }
  }

  formatDerivedFile(file: { [key: string]: any }, result: { [key: string]: any }): void {
    const hash = file.digests['SHA-256']
    const source = {
      resources: [
        { ...file, resourceReference: { name: 'file' } }
      ]
    }
    const formatted: any = {}

    this.formatters.forEach(formatter => {
      formatter.format(source, formatted)
    })

    if (!this.isSimple(formatted)) {
      result[hash] = formatted
    }
  }

  isSimple(detail: { [key: string]: any }): boolean {
    const values: any = Object.values(detail)
    if (values.length === 1 && values[0] && values[0].overview && Object.keys(values[0]).length === 1) {
      return true
    } else {
      return false
    }
  }
}

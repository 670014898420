export type ExtractedStringsFilterStat = {
    types: {
        origin: {[key: string]: number},
        type: {[key: string]: number}
    },
    total: number
}

export enum ReportExportStringsMode {
    All = 'all',
    Interesting = 'interesting',
    PreferInteresting = 'prefer_interesting'
}

import React, { createContext, useEffect, useState, useContext } from 'react';
import { ReportsWrapper, ReportsWrapperType } from '../wrapper';
import { ReportFormatter, ReportFilter } from '../query';
import { reportsService } from '../services';
import { applicationConfiguration } from '../../shared';
import { logger } from '../../../components';
import { unescapeObject, unescapeObjectKeys } from '../helpers';
import { ApplicationConfiguration } from '../../shared/types';
import { useReport } from '../hooks';
import { ForbiddenError } from 'app/modules/shared/exceptions';
import NotFoundPage from 'app/components/errors/not-found';
import { useExport } from './export-context';
import { useDetectExport } from 'app/modules/shared/helpers';

export const reportContext = createContext<ReportsWrapperType>(undefined as any)

/**
 * Context for loading and providing report data
 */
export function WithReportContext({ children }: { children: JSX.Element }): JSX.Element | null {
  let timer: NodeJS.Timeout | null = null
  let fetching = false

  const { flowId, reportId, hash } = useReport()
  const defaultReportsWrapper = new ReportsWrapper(undefined)
  const exporting = useDetectExport()
  const config = useContext(applicationConfiguration)
  const [error, setError] = useState<any>(null)
  const [forceRefresh, setForceRefresh] = useState<boolean>(false)
  const [reportsWrapper, setReportsWrapper] = useState<ReportsWrapperType>(defaultReportsWrapper)

  reportsWrapper.setRefreshReportCallback(() => {
    setForceRefresh(!forceRefresh);
  })

  async function fetchData() {
    if (fetching) return

    fetching = true

    try {
      const newReportsWrapper = await fetchReport(flowId, hash, reportId as string, config, exporting)

      // Do not compare old data to new, but always assign new data
      // to state property, to be able to update scan progress bar constantly.
      setReportsWrapper(newReportsWrapper);
    } catch (error: any) {
      console.error(error)
      setError(error)
      timer && clearInterval(timer)
    } finally {
      fetching = false
    }
  }

  useEffect(() => {
    fetchData()
  }, [forceRefresh]);

  useEffect(() => {
    const finish = (
      (
        reportsWrapper.checkAllReportsReady() &&
        reportsWrapper.getFilesLoadingState() &&
        reportsWrapper.getAdditionalStepsState()
      ) || reportsWrapper.getJobError()
    )

    if (!finish) {
      const isEmpty = reportsWrapper.isEmpty()
      const pause = isEmpty ?
        config.reports.queryInterval.initial :
        reportsWrapper.getDataPollPause() || config.reports.queryInterval.withData

      timer = setInterval(fetchData, pause * 1000)
    }

    return () => {
      timer && clearInterval(timer)
    }
  }, [flowId, reportsWrapper, config, hash, reportId, forceRefresh])

  if (error) {
    if (error.code === 404) {
      return <NotFoundPage />
    }
    if (error.code === 401) {
      throw new ForbiddenError()
    }

    throw error
  }

  if (!flowId && !hash) {
    return null
  }

  return (
    <reportContext.Provider value={reportsWrapper}>
      {children}
    </reportContext.Provider>
  )
}

/**
 * Fetch report from server and format it.
 * @param flowId
 */
async function fetchReport(
  flowId: string | undefined,
  hash: string | undefined,
  reportId: string,
  config: ApplicationConfiguration,
  exporting: boolean
) {
  const formatter = new ReportFormatter(config, exporting)
  const filterParams = getFilterParams()

  let report: any = null
  if (flowId) {
    report = await reportsService.getReports(flowId, filterParams)
  } else if (hash) {
    report = await reportsService.getReport(hash, reportId, filterParams)
  } else {
    return 'Invalid arguments'
  }

  unescapeObjectKeys(report)
  unescapeObject(report)
  logger.debug('Plain report: ', report);
  const formatted = formatter.format(report)
  logger.debug('Formatted report: ', formatted);

  const wrapped = new ReportsWrapper(formatted)

  return wrapped
}

/**
 * Turn filter object into query string
 */
function getFilterParams(): string {
  const filter = new ReportFilter()

  return filter.toQueryParams()
}
